import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Select, InputLabel, FormControl } from '@material-ui/core';
import { useHistory } from "react-router-dom";
function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="http://trinax.com/">
                Trinax
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    headerLabel: {
        paddingTop: 20,
        paddingBottom: 20
    },
    roomHeaderLabel: {
        marginTop: 20,
        marginBottom: 20
    }
}));

const LoginPageComponent = () => {
    const mHistory = useHistory();
    const [state, setState] = React.useState({
        room: '',
        username: '',
        password: ''
    })
    const classes = useStyles();
    const headerIcon = process.env.PUBLIC_URL + '/assets/images/logo/trinax-red.png';
    const handleChange = (event) => {
        const name = event.target.name;
        setState({
            ...state,
            [name]: event.currentTarget.value,
        });
    };
    const validateAndLogin = () => {
        if (state.room !== '' && state.username !== '' && state.password === 'admin_trinax@123') {
            localStorage.setItem('name', state.username);
            localStorage.setItem('roomName', state.room);
            localStorage.setItem('password', state.password);
            mHistory.push('/webinar')
        }
    }
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <div>
                    <img src={headerIcon} />
                </div>
                <Typography className={classes.headerLabel} component="h1" variant="h5">
                    Presenter Sign In
                </Typography>
                <form className={classes.form} noValidate>
                    <InputLabel className={classes.roomHeaderLabel} htmlFor="webinar-room">Room to join</InputLabel>
                    <Select
                        variant="outlined"
                        native
                        name="room"
                        fullWidth
                        value={state.room}
                        onChange={handleChange}
                        inputProps={{
                            name: 'room',
                            id: 'webinary-room',
                        }}>
                        <option aria-label="None" value="0">Select Room</option>
                        <option value={"AboutDogs"}>About Dogs</option>
                        <option value={"AboutCats"}>About Cats</option>
                        <option value={"AboutSustainability"}>About Sustainability</option>
                    </Select>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        onChange={handleChange}
                        value={state.username}
                        label="Presenter Name"
                        name="username"
                        autoComplete="text"
                        autoFocus
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        onChange={handleChange}
                        value={state.password}
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    {/* <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me" /> */}
                    <Button
                        type="button"
                        fullWidth
                        onClick={() => validateAndLogin()}
                        variant="contained"
                        color="primary"
                        className={classes.submit}>
                        Sign In
                    </Button>
                    {/* <Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="#" variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                    </Grid> */}
                </form>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    )
}

export const LoginPage = LoginPageComponent;