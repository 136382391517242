import { ChatActionTypes } from "../action-types";
import { User } from "../../handlers/request-worker/rocket-chat/interfaces/response.interface";
import { RocketResponseInterfaces } from "../../handlers/request-worker/rocket-chat/interfaces";
interface IState {
    users: User[],
    activeRoomId: string,
    activeRoomMessages: RocketResponseInterfaces.IChatRoomMessageItem[],
    friendUserName: string,
    isFriendOnline: boolean,
    isMessageLoaded: boolean
}
const initialState: IState = {
    users: [],
    activeRoomId: '',
    activeRoomMessages: [],
    friendUserName: "",
    isFriendOnline: true,
    isMessageLoaded: false
}
export const chatReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ChatActionTypes.CHAT_ACTIVE_MEMBERS:
            return Object.assign({},
                {
                    ...state,
                    users: action.payload.users
                });
        case ChatActionTypes.CHAT_ACTIVE_ROOM_ID:
            return Object.assign({}, {
                ...state,
                activeRoomId: action.payload.activeRoomId
            })
        case ChatActionTypes.CHAT_ACTIVE_ROOM_MESSAGES:
            return Object.assign({}, {
                ...state,
                activeRoomMessages: action.payload.activeRoomMessages.reverse(),
                friendUserName: action.payload.friendUserName,
                isFriendOnline: action.payload.isFriendOnline,
                isMessageLoaded: true
            });
        case ChatActionTypes.CHAT_ACTIVE_IS__MESSAGE_LOADED:
            return Object.assign({}, {
                ...state,
                isMessageLoaded: action.payload.isMessageLoaded
            });
        default:
            return state;
    }
}