import React from 'react';


const CommonPageComponent = (props) => {
    return (
        <>
            {
                props.children
            }
        </>
    )
};

export const CommonPageLayout = CommonPageComponent;