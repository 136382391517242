import { LoginPage } from "../pages/login-page";
import { WebinarAttendPage } from "../pages/webinar-page/webinar-room";

export const AppRouteEntries = [
    {
        path: '/',
        exact: true,
        component: LoginPage
    }
    , {
        path: '/webinar',
        exact: true,
        component: WebinarAttendPage
    }

    // , {
    //     path: '/webinar/room',
    //     exact: true,
    //     component: ProductShowcasePage
    // }
]