import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { AppRouteEntries } from './app-routes';
import { CommonPageLayout } from '../pages';


export const AppRoutes = (props) => {
    return (
        <Switch>
            {
                AppRouteEntries.map((route, index) => {
                    const { component: Component, ...rest } = route;
                    return (
                        <Route {...rest} key={index} render={
                            matchProps => (
                                <CommonPageLayout>
                                        <Component  {...matchProps}  />
                                </CommonPageLayout>
                            )
                        } />
                    )
                })
            }
        </Switch>
    )
}