import React, { useEffect } from 'react';
import './App.css';
import { AppRoutes } from './route';
import { SESSION_STORAGE_KEY_MY_EMAIL, SESSION_STORAGE_KEY_REP_EMAIL, SESSION_STORAGE_KEY_ROCKET_ACCESS_TOKEN, SESSION_STORAGE_KEY_ROCKET_USER_ID } from './constants/app-constants';
import { dispatchIsEmailSent, dispatchIsEmailVerified, dispatchUserRocketTokenAndId } from './redux/action-dispatchers/auth-actions.dispatcher';

function App() {
  useEffect(() => {
    const myEmailId = localStorage.getItem(SESSION_STORAGE_KEY_MY_EMAIL);
    const repEmailId = localStorage.getItem(SESSION_STORAGE_KEY_REP_EMAIL);
    const rocketAuthToken = localStorage.getItem(SESSION_STORAGE_KEY_ROCKET_ACCESS_TOKEN);
    const rocketUserId = localStorage.getItem(SESSION_STORAGE_KEY_ROCKET_USER_ID);

    if (myEmailId != null && repEmailId !== null && rocketAuthToken != null && rocketUserId != null) {
      dispatchIsEmailSent(true);
      dispatchIsEmailVerified(true, {
        _id: '',
        createdOn: 1,
        ipAddress: 1,
        refreshToken: '',
        repEmailId: repEmailId,
        userEmailId: myEmailId
      });
      dispatchUserRocketTokenAndId(rocketAuthToken, rocketUserId)
    }
  }, [])
  return (
    <AppRoutes />
  );
}

export default App;
