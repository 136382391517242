import React from 'react';

const WebinarChatBoxComponent = (props) => {
    const [messageToSend, setMessageToSend] = React.useState("");
    return (
        <div className="card-footer">
            <div className="input-group">
                {/* <textarea value={messageToSend} onChange={(event) => {
                    setMessageToSend(event.currentTarget.value)
                }} rows={1} name="" autoComplete={true} autoCorrect={true} className="form-control type_msg" placeholder="Type your message..."></textarea> */}
                {/* <div className="input-group-append">
                    <span className="input-group-text" style={{ backgroundColor: '#FFF', border: '0px' }}>
                        <button className="send_btn" >Send</button>
                    </span>
                </div> */}
            </div>
        </div>
    )
}


export const WebinarChatBox = WebinarChatBoxComponent;