import * as AuthActionTypes from '../action-types/auth-action-types'
import { IUserInfo } from '../../handlers/request-worker/core-api/interfaces/response.interface';
import * as SessionKeys from "../../constants/app-constants";
interface IStateInterface {
    isEmailSent: boolean,
    isEmailVerified: boolean,
    userData: IUserInfo,
    userRocketToken: string,
    userRocketId: string
}
const initialState: IStateInterface = {
    isEmailSent: false,
    isEmailVerified: false,
    userData: {
        _id: "",
        createdOn: 0,
        ipAddress: "",
        refreshToken: "",
        repEmailId: "",
        userEmailId: ""
    },
    userRocketId: '',
    userRocketToken: ''
}

export const authReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case AuthActionTypes.IS_EMAIL_SENT:
            return Object.assign({}, state, {
                isEmailSent: action.data.isEmailSent
            });
        case AuthActionTypes.IS_EMAIL_VERIFIED:
            return Object.assign({}, state, {
                isEmailVerified: action.data.isEmailVerified,
                userData: action.data.userData
            });

        case AuthActionTypes.UPDATE_ROCKET_TOKEN_AND_ID:
            return Object.assign({}, state, {
                userRocketId: action.data.userRocketId,
                userRocketToken: action.data.userRocketToken
            });
        default:
            return state;
    }
};