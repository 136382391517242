import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap';
import Jitsi from "./jitsi";
import { WebinarChatBox } from './webinar-chat-box';
const chatItems = [
    {}, {}, {}, {}, {}
]
const WebinarAttendPageComponent = () => {
    const mHistory = useHistory();
    useEffect(() => {
        const userName = localStorage.getItem("name");
        const password = localStorage.getItem("password");
        const roomName = localStorage.getItem("roomName");
        console.log("Data on webinar :" + userName + password + roomName)
        if (userName === undefined || password === undefined || roomName === undefined) {
            mHistory.push("/")
        }
    }, []);
    return (
        <div style={{ backgroundColor: 'rgb(51, 51, 51)' }}>
            <Row style={{ padding: 10, paddingLeft: 30, paddingRight: 50 }}>
                <Col xs={11} xl={11}>
                    <Link to="/">
                        <img src={process.env.PUBLIC_URL + '/assets/images/icons/exit.png'} style={{ width: '60px' }} />
                    </Link>
                </Col>
                <Col xs={1} xl={1} style={{ textAlign: 'right', padding: 0 }}>
                    <img src={process.env.PUBLIC_URL + '/assets/images/icons/live.png'} style={{ width: '60px' }} />
                </Col>
            </Row>

            <Row style={{ padding: 0 }}>
                <Col xs={12} xl={12}>
                    <img src={process.env.PUBLIC_URL + '/assets/images/background/webinar/webinar-live-header.png'} style={{ width: '100%' }} />
                </Col>
            </Row>
            <Row style={{ padding: 0 }}>
                <Col xs={9} xl={9} style={{ padding: 0, height: '500px' }}>
                    <Jitsi
                        displayName={localStorage.getItem("name")}
                        roomName={localStorage.getItem("roomName")}
                    />
                    <img src={process.env.PUBLIC_URL + '/assets/images/background/landing/auditorium.png'} style={{ width: '100%', position: "absolute", bottom: 0, height: '100px' }} />
                </Col>
                <Col xs={3} xl={3} style={{ padding: 10, backgroundColor: '#FFF' }}>
                    <Row>
                        <Col xs={7} xl={7}><div className={'chatbox-header'}>QUESTIONS</div></Col>
                        <Col xs={5} xl={5}><div className={'chatbox-attendees'}>Attendees : 25</div></Col>
                    </Row>
                    <div className={"webinar-chat-container"}>
                        {
                            chatItems.map((item) => {
                                return (
                                    <div className="webinar-chat-item">
                                        <Row>
                                            <Col xs={7} xl={7}><div className={'username'}>Tushar Singh</div></Col>
                                            <Col xs={5} xl={5} style={{ paddingRight: 50 }}><div className={'time'}>10:45 PM</div></Col>
                                        </Row>
                                        <Row>
                                            <Col xs={11} xl={11}>
                                                <div className="message">Hi This is tushar singh</div>
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <WebinarChatBox />
                </Col>
            </Row>

        </div>
    )
}

export const WebinarAttendPage = WebinarAttendPageComponent;