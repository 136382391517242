import { store } from ".."
import * as AuthActionTypes from '../action-types/auth-action-types'
import { IUserInfo } from "../../handlers/request-worker/core-api/interfaces/response.interface"

export const dispatchIsEmailSent = (isEmailSent: boolean) => {
    store.dispatch({
        type: AuthActionTypes.IS_EMAIL_SENT,
        data: {
            isEmailSent: isEmailSent
        }
    })
}

export const dispatchIsEmailVerified = (isEmailVerified: boolean, userData: IUserInfo) => {
    store.dispatch({
        type: AuthActionTypes.IS_EMAIL_VERIFIED,
        data: {
            isEmailVerified: isEmailVerified,
            userData: userData
        }
    })
}

export const dispatchUserRocketTokenAndId = (userToken: string, userId: string) => {
    store.dispatch({
        type: AuthActionTypes.UPDATE_ROCKET_TOKEN_AND_ID,
        data: {
            userRocketToken: userToken,
            userRocketId: userId
        }
    })
}

